/**
 * @generated SignedSource<<0e05d49cb4763c8a9a0642be56eba81a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
export type GuildTypes = "GUILD" | "NETWORK" | "%future added value";
export type ImageContentType = "GIF" | "JPEG" | "PNG" | "WEBP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GuildCardNew_guild$data = {
  readonly backgroundPhoto: {
    readonly contentType: ImageContentType;
    readonly rowId: String;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly memberCount: number;
  readonly myMembership: {
    readonly membershipLevel: GuildMembershipLevel;
  } | null | undefined;
  readonly name: string;
  readonly rowId: String;
  readonly slugId: string;
  readonly type: GuildTypes | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GuildPrimaryPhotoNew_guild">;
  readonly " $fragmentType": "GuildCardNew_guild";
};
export type GuildCardNew_guild$key = {
  readonly " $data"?: GuildCardNew_guild$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuildCardNew_guild">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuildCardNew_guild",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slugId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GuildMembership",
      "kind": "LinkedField",
      "name": "myMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membershipLevel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuildPrimaryPhotoNew_guild"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "backgroundPhoto",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Guild",
  "abstractKey": null
};
})();

(node as any).hash = "3379b2bb2879ab04aca86a6e2dedc112";

export default node;
