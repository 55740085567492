import React, { useState, useMemo, useRef } from 'react'
import { graphql, useFragment } from 'react-relay'

import { useNavigate, useLocation, useMatch } from 'react-router-dom'

import {
  YStack,
  XStack,
  Tooltip,
  View,
  DialogHandle,
  AnimatePresence,
} from 'tamagui'

import { BACKGROUNDS } from '../../data/config/images'
import { IMAGEKIT_URL } from '../../data/config/url'

import { useAuthContext } from '../../data/useAuthContext'

import { Card } from '../Card'
import { H3, Text, Paragraph } from '../Text'
import { FormattedMarkdown } from '../FormattedMarkdown/FormattedMarkdown'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { Image } from '../Image'
import { Button } from '../Button/Button'
import { Dialog } from '../Dialog/Dialog'

import { GuildPrimaryPhoto } from './GuildPrimaryPhoto'

import { pickFromDefaults } from '../util'

import {
  GuildCardNew_guild$data,
  GuildCardNew_guild$key,
} from './__generated__/GuildCardNew_guild.graphql'

const ALLOWED_ELEMENTS = ['a', 'p']

export const GuildCard = ({
  guild,
  showSettingsButton = false,
  showMoreDialog = false,
  limitDescriptionLines,
  expand = false,
  ...props
}: {
  guild: GuildCardNew_guild$key
  showSettingsButton?: boolean
  showMoreDialog?: boolean
  limitDescriptionLines?: number
  expand?: boolean
}) => {
  const data = useFragment(
    graphql`
      fragment GuildCardNew_guild on Guild {
        rowId
        slugId

        name
        description

        type

        myMembership {
          membershipLevel
        }

        memberCount

        ...GuildPrimaryPhotoNew_guild

        backgroundPhoto {
          rowId
          contentType
        }
      }
    `,
    guild
  )

  const isGuildScreen = useMatch(`/${data.slugId}/*`)

  const dialogRef = useRef<DialogHandle>(null)

  const [descriptionHovered, setDescriptionHovered] = useState(false)

  const expandMarkdownChildren = !!showMoreDialog && (
    <>
      <XStack justifyContent='center' marginTop={-28 / 2} height={28 / 2}>
        <Tooltip placement='top' delay={100}>
          <Tooltip.Trigger tag='dialog'>
            <Dialog ref={dialogRef} modal>
              <Dialog.Trigger
                aria-label={`Expand Description for ${data.name}`}
              >
                <AnimatePresence>
                  {descriptionHovered && (
                    <View
                      animation='mediumSpring'
                      backgroundColor='$grey2'
                      // borderRadius='50%'
                      borderRadius='$2'
                      cursor='pointer'
                      opacity={0.8}
                      style={{
                        backdropFilter: 'blur(8px) saturate(250%)',
                        WebkitBackdropFilter: 'blur(8px) saturate(250%)',
                      }}
                      enterStyle={{ opacity: 0, y: -10, scale: 0.9 }}
                      exitStyle={{ opacity: 0, y: -10, scale: 0.9 }}
                      hoverStyle={{
                        opacity: 1,

                        backgroundColor: 'rgba(105, 102, 118, 0.8)',
                      }}
                    >
                      <Icon
                        padding={2}
                        // name='arrow-down-filled'
                        name='window-new'
                        size='$7'
                        color='$grey7'
                        hoverStyle={{ color: '$grey2' }}
                      />
                    </View>
                  )}
                </AnimatePresence>
              </Dialog.Trigger>

              <Dialog.Adapt when='xs'>
                <Dialog.Sheet key='sheet'>
                  <Dialog.Sheet.Handle zIndex={200_000} />
                  <Dialog.Sheet.Frame
                    padding={0}
                    gap='$4'
                    shadowColor='$grey6'
                    shadowRadius='$2'
                    borderTopRightRadius='$6'
                    borderTopLeftRadius='$6'
                  >
                    <Dialog.Sheet.ScrollView
                      // borderTopRightRadius='$6'
                      // borderTopLeftRadius='$6'
                      paddingTop={0}
                      paddingHorizontal={0}
                    >
                      <YStack gap='$4'>
                        <Dialog.Adapt.Contents />
                      </YStack>
                    </Dialog.Sheet.ScrollView>
                  </Dialog.Sheet.Frame>
                </Dialog.Sheet>
              </Dialog.Adapt>

              <Dialog.Portal>
                <Dialog.Content
                  elevate
                  key='content'
                  // gap='$4'
                  $gtXs={{
                    minWidth: 300,
                    maxWidth: 400,
                    minHeight: 300,
                    maxHeight: 600,
                  }}
                  padding={0}
                  forceMount
                >
                  <GuildCardPhotos guild={data} />

                  <YStack gap='$4' paddingVertical='$4' paddingHorizontal='$5'>
                    <Dialog.Title size='$6'>{data.name}</Dialog.Title>

                    <Dialog.Description>
                      <YStack>
                        <FormattedMarkdown
                          allowedElements={ALLOWED_ELEMENTS}
                          formattedVariant='PLAIN_TEXT'
                          textSize='$4'
                        >
                          {data.description}
                        </FormattedMarkdown>
                      </YStack>
                    </Dialog.Description>

                    <XStack
                      alignItems='center'
                      justifyContent='space-between'
                      height={16}
                      marginTop='$1'
                    >
                      <XStack gap='$1'>
                        <Text
                          size='$4'
                          fontWeight='$7'
                          style={{
                            fontVariantNumeric: 'tabular-nums slashed-zero',
                            letterSpacing: -0.3,
                          }}
                        >
                          {data.memberCount >= 1000
                            ? `${(data.memberCount / 1000)
                                .toFixed(1)
                                .replace('.0', '')}K`
                            : data.memberCount}
                        </Text>{' '}
                        <Text size='$4' color='$grey7'>
                          {data.memberCount === 1 ? 'Member' : 'Members'}
                        </Text>
                      </XStack>

                      <GuildCardButton
                        showSettingsButton={showSettingsButton}
                        data={data}
                        onPress={() => {
                          dialogRef.current?.open(false)
                        }}
                      />
                    </XStack>
                  </YStack>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog>
          </Tooltip.Trigger>

          <Tooltip.Content
            animation='fastSpring'
            enterStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
            scale={1}
            x={0}
            y={0}
            opacity={1}
            borderColor='rgba(105, 102, 118, 1.0)'
            backgroundColor='rgba(105, 102, 118, 0.8)'
            style={{
              backdropFilter: 'blur(8px) saturate(250%)',
              WebkitBackdropFilter: 'blur(8px) saturate(250%)',
            }}
          >
            <Tooltip.Arrow
              borderColor='rgba(105, 102, 118, 1.0)'
              backgroundColor='rgba(105, 102, 118, 0.7)'
              style={{
                backdropFilter: 'blur(8px) saturate(250%)',
                WebkitBackdropFilter: 'blur(8px) saturate(250%)',
              }}
            />

            <Paragraph size='$4' color='$grey0' fontWeight='$5'>
              Expand
            </Paragraph>
          </Tooltip.Content>
        </Tooltip>
      </XStack>
    </>
  )

  const content = (
    <Card {...props} padding={0} flex={expand ? 1 : undefined}>
      <GuildCardPhotos guild={data} />

      <YStack
        gap='$4'
        paddingVertical='$4'
        paddingHorizontal='$5'
        flex={expand ? 1 : undefined}
      >
        <H3 size='$6'>{data.name}</H3>

        {data.description ? (
          <YStack
            flex={expand ? 1 : undefined}
            onHoverIn={() => {
              setDescriptionHovered(true)
            }}
            onHoverOut={() => {
              setDescriptionHovered(false)
            }}
          >
            <FormattedMarkdown
              allowedElements={ALLOWED_ELEMENTS}
              formattedVariant='PLAIN_TEXT'
              textSize='$4'
              numberOfLines={limitDescriptionLines}
              expandMarkdownChildren={expandMarkdownChildren}
            >
              {data.description}
            </FormattedMarkdown>
          </YStack>
        ) : (
          <YStack flex={expand ? 1 : undefined} />
        )}

        <XStack
          alignItems='center'
          justifyContent='space-between'
          height={16}
          marginTop='$1'
        >
          <XStack gap='$1'>
            <Text
              size='$4'
              fontWeight='$7'
              style={{
                fontVariantNumeric: 'tabular-nums slashed-zero',
                letterSpacing: -0.3,
              }}
            >
              {data.memberCount >= 1000
                ? `${(data.memberCount / 1000).toFixed(1).replace('.0', '')}K`
                : data.memberCount}
            </Text>{' '}
            <Text size='$4' color='$grey7'>
              {data.memberCount === 1 ? 'Member' : 'Members'}
            </Text>
          </XStack>

          <GuildCardButton
            showSettingsButton={showSettingsButton}
            data={data}
          />
        </XStack>
      </YStack>
    </Card>
  )

  if (isGuildScreen) {
    return content
  }

  return (
    <Link
      display='flex'
      flex={expand ? 1 : undefined}
      href={`/${data.slugId}`}
      hoverStyle={{ textDecorationLine: 'none' }}
      color='$grey9'
      flexDirection='column'
      size='$4'
    >
      {content}
    </Link>
  )
}

const GuildCardPhotos = ({ guild }: { guild: GuildCardNew_guild$data }) => {
  const displayedBackground = useMemo(() => {
    if (!guild.backgroundPhoto?.contentType) {
      const defaultBackground = pickFromDefaults(guild.rowId, BACKGROUNDS)

      return `${IMAGEKIT_URL}/tr:w-500,h-200,dpr-3/${defaultBackground}`
    }

    return `${IMAGEKIT_URL}/tr:w-500,h-200,dpr-3/${
      guild.backgroundPhoto.rowId
    }.${guild.backgroundPhoto.contentType.toLowerCase()}`
  }, [guild.backgroundPhoto, guild.rowId])

  return (
    <YStack maxWidth='100%'>
      <Image
        alt={`Cover Photo for ${guild.name}`}
        aspectRatio={500 / 200}
        src={displayedBackground}
        borderTopLeftRadius='$6'
        borderTopRightRadius='$6'
        maxHeight={30 * 4}
        style={{ objectFit: 'cover' }}
        maxWidth='100%'
      />

      <XStack
        marginTop={-40}
        paddingHorizontal='$5'
        gap='$4'
        alignItems='flex-end'
      >
        <GuildPrimaryPhoto
          guild={guild}
          size='$20'
          borderRadius='$4'
          boxShadow='0 0 0 3px #FFFFFF'
        />

        {guild.type === 'NETWORK' && (
          <XStack marginBottom='$2'>
            <Text
              size='$4'
              fontWeight='$6'
              backgroundColor='$lightBlue9'
              color='$grey0'
              borderRadius='$4'
              paddingVertical='$1'
              paddingHorizontal='$3'
            >
              Network
            </Text>
          </XStack>
        )}
      </XStack>
    </YStack>
  )
}

const GuildCardButton = ({
  showSettingsButton,
  data,
  onPress,
}: {
  showSettingsButton: boolean
  data: GuildCardNew_guild$data
  onPress?: () => any
}) => {
  const { accessToken } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()

  const tooltipText = useMemo(() => {
    switch (data.myMembership?.membershipLevel) {
      case 'ORGANIZER': {
        return `You're an organizer`
      }
      case 'EDITOR': {
        return `You're an editor`
      }
      case 'MEMBER': {
        return `You're a member`
      }
    }

    return ''
  }, [data.myMembership])

  if (!data.myMembership) {
    return (
      <Button
        borderRadius={15}
        marginRight={-11}
        height={30}
        minHeight={30}
        paddingLeft='$2'
        paddingRight='$3'
        successFlash={false}
        onPress={(event) => {
          event.preventDefault()
          event.stopPropagation()

          if (accessToken) {
            navigate(`/${data.slugId}/confirm`)
          } else {
            navigate('/sign-up', {
              state: {
                backgroundLocation:
                  (location.state?.backgroundLocation as Location) || location,
                authRedirectPath: `/${data.slugId}/confirm`,
              },
            })
          }

          if (onPress) {
            onPress()
          }
        }}
        secondary
      >
        <XStack gap='$1' alignItems='center'>
          <Icon size='$4' name='chevron-right' />

          <Button.Text size='$4' secondary>
            Join
          </Button.Text>
        </XStack>
      </Button>
    )
  }

  if (showSettingsButton) {
    return (
      <Link
        href={`/${data.slugId}/settings`}
        hoverStyle={{ textDecorationLine: 'none' }}
        onPress={onPress}
      >
        <Button
          borderRadius={15}
          marginRight={-11}
          height={30}
          minHeight={30}
          paddingRight='$3'
          paddingLeft='$2'
          secondary
        >
          <XStack gap='$1' alignItems='center'>
            <Icon size='$4' name='settings-filled' />

            <Button.Text size='$4' secondary>
              Settings
            </Button.Text>
          </XStack>
        </Button>
      </Link>
    )
  }

  return (
    <Tooltip placement='top' delay={100}>
      <Tooltip.Trigger tag='dialog'>
        <Icon size='$4' name='checkmark-filled' />
      </Tooltip.Trigger>

      <Tooltip.Content
        animation='fastSpring'
        enterStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
        exitStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
        scale={1}
        x={0}
        y={0}
        opacity={1}
        borderColor='rgba(105, 102, 118, 1.0)'
        backgroundColor='rgba(105, 102, 118, 0.8)'
        style={{
          backdropFilter: 'blur(8px) saturate(250%)',
          WebkitBackdropFilter: 'blur(8px) saturate(250%)',
        }}
      >
        <Tooltip.Arrow
          borderColor='rgba(105, 102, 118, 1.0)'
          backgroundColor='rgba(105, 102, 118, 0.7)'
          style={{
            backdropFilter: 'blur(8px) saturate(250%)',
            WebkitBackdropFilter: 'blur(8px) saturate(250%)',
          }}
        />

        <Paragraph size='$4' color='$grey0' fontWeight='$5'>
          {tooltipText}
        </Paragraph>
      </Tooltip.Content>
    </Tooltip>
  )
}
